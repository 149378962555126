<template>
  <v-btn
    @click="$emit('click')"
    @click.stop="$emit('clickStop')"
    :id="id"
    :block="block"
    :depressed="depressed"
    :outlined="outlined"
    :disabled="disabled"
    :small="small"
    :color="colorBnn[color]"
    :height="height"
    :width="width"
    :loading="loading"
    class="btn-bnn-default"
    :class="customClass"
  >
    <slot name="content"></slot>
    {{ title }}
  </v-btn>
</template>

<script>
import colorBnn from '@/config/color'
export default {
  computed: {
    customClass() {
      return {
        'text-btn': colorBnn[this.color] === '#0071e3', //'#459d43',
        'text-small': this.textSmall,
        'text-large': this.textLarge,
        'font-bold': this.fontNormal ? false : this.fontBold,
        'font-normal': this.fontBold ? false : this.fontNormal,
      }
    },
  },

  props: {
    id: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    block: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    textSmall: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    textLarge: {
      type: Boolean,
      default: false,
    },
    fontBold: {
      type: Boolean,
      default: true,
    },
    fontNormal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      colorBnn: colorBnn,
    }
  },
}
</script>

<style lang="stylus" scoped>
.btn-bnn-default {
    padding: 1.25rem 0 !important
    font-weight: bold !important
    font-size: 0.875rem !important
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
    height 20px !important
}

text-white {
  color: #fff
}

.text-btn
    color $color-btn-base !important

.text-small {
  font-size: 0.675rem !important
}

.text-large {
  font-size: 1.3rem !important
}

.font-bold {
  font-weight: bold !important
}

.font-normal {
  font-weight: normal !important
}
</style>
